$(document).ready(function () {
    $('.login-header a').on('click', function (e) {
        e.preventDefault();
    });
    //If body height is lesses than window height(means if white space is left at bottom)
    var bodyHeight = $('body').height(),
            windowHeight = $(window).height();
    if (bodyHeight < windowHeight) {
        var heightDiff = parseInt(windowHeight) - parseInt(bodyHeight),
                loginFormHeight = parseInt($('.login-form-container').height()) + heightDiff;
        $('.login-form-container').height(loginFormHeight);
    }
    //END
    //Login submit form
    $('.login-form').on('submit', function (e) {
        var $this = $(this);
        //disabling submit button
        $this.find('button[type="submit"]').attr('disabled', 'disabled').addClass('active');
        $.ajax({
            type: "POST",
            url: $this.attr('action'),
            data: $this.serialize(),
            dataType: 'json',
            success: function (response) {
                //removing all error classes
                $this.find('.has-error').removeClass('has-error');
                $this.find('.error-msg').remove();

                $this.find('button[type="submit"]').removeAttr('disabled').removeClass('active');
                $this.find('[type="password"], [type="email"]').val('');

                location.href = response.url;
            },
            error: function (error) {
                var formSel = $('.login-form');
                formSel.find('button[type="submit"]').removeAttr('disabled').removeClass('active');
                hpApp.ajaxInputError(error, formSel);
            }
        });
        e.preventDefault(e);
    });
    //END Login submit form
});